import './css/dashboard.css';
import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function Dashboard(){


    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    const [suppliersCount, setSuppliersCount] = useState(0);
    const [productsCount, setProductsCount] = useState(0);
    const [postsCount, setPostsCount] = useState(0);


    const [AllSuppliers, setAllSuppliers] = useState([]);

    


    
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
                navigate('/dashboard');
            }else{
                // navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    const getCount = (tableName, setCount) => {
        axios.post('get-item-count', {
            table: tableName,
        })
        .then((res) => {
            if (res.data) {
                setCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    
    const getProductsCount = () => {
        getCount('products', setProductsCount);
    };


    const getSuppliersCount = () => {
        getCount('suppliers', setSuppliersCount);
    };
    
    
    const getPostsCount = () => {
        getCount('posts', setPostsCount);
    };
    


    const GetAllSuppliers = ()=>{
        axios.post('AllItems', {
            table: 'suppliers',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllSuppliers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }

    
useEffect(() => {
    getPostsCount();
    getProductsCount();
    getSuppliersCount();
    GetAllSuppliers();

    getIfLogin();
}, []);




    

    return(
        <>
            <section className='dashboard'>
                <div className='stats-content'>
                    <Link to="/all-suppliers">
                        <div className='stat'>
                            <i className="las la-users"></i>
                            <div className='info'>
                                <h1>{suppliersCount}</h1>
                                <h2> الموردين </h2>
                            </div>
                        </div>
                    </Link>
                    

                    <Link to="/all-products">
                        <div className='stat'>
                        <i className="las la-question"></i>
                            <div className='info'>
                                <h1>{productsCount}</h1>
                                <h2> المنتجات </h2>
                            </div>
                        </div>
                    </Link>


                    <Link to="/all-posts">
                        <div className='stat'>
                        <i className="las la-poll-h"></i>
                            <div className='info'>
                                <h1>{postsCount}</h1>
                                <h2> الاخبار </h2>
                            </div>
                        </div>
                    </Link>

                    
                </div>




                <div className='results-table'>

                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>الصورة</th>
                            <th> الاسم </th>
                            <th>خيارات</th>

                        </tr>
                    </thead>
                    <tbody>

                    {AllSuppliers && AllSuppliers.length > 0 ? (
                        AllSuppliers.map((supplier) => (
                            <tr key={supplier.id}>
                            <td><h6>{supplier.id}</h6></td>
                            <td><img src={`uploads//${supplier.img}`} alt="User avatar"/></td>
                            <td><h2>{supplier.name}</h2></td>
                            <td><Link to={`/edit-supplier?id=${supplier.id}`}> تعديل </Link></td>
                            </tr>
                        ))
                        ) : (
                        <tr>
                            <td colSpan="4"> فارغ </td>
                        </tr>
                        )}

                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default Dashboard;
import './css/contact.css';

import { useState,useEffect,useRef  } from "react";

import axios from '../config/index';


function Contact() {



    
    
    const [geolocation, setGeoLocation] = useState("");



    const getLocation = (itemId) => {
        let table = "social";
        var itemId = 6;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setGeoLocation(res.data);
            }
        })
        .catch((err) => console.log(err));
    };



        
    const [email, setEmail] = useState("");



    const getEmail = (itemId) => {
        let table = "social";
        var itemId = 7;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setEmail(res.data);
            }
        })
        .catch((err) => console.log(err));
    };



            
    const [phone, setPhone] = useState("");



    const getPhone = (itemId) => {
        let table = "social";
        var itemId = 8;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setPhone(res.data);
            }
        })
        .catch((err) => console.log(err));
    };

    

    useEffect(() => {

        getLocation();
        getEmail();
        getPhone();


    }, []);




return (
    <>
    <section className='contact'>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-4 col-md-4 col-sm-6 co-12'>
            <div className='contact-content-text'>
                <h2>تواصل معنا الآن</h2>
                <h1>اتصل بنا الآن</h1>
                <p>فريق دعم العملاء المخصص لدينا متاح دائمًا للرد على استفساراتك، طلباتك، أو مخاوفك. نحن ملتزمون بتقديم ردود سريعة ومفيدة لضمان أن تكون تفاعلك معنا سلسًا ومرضيًا.</p>

                <div className='contact-info'>
                <div className='info'>
                    <i class="las la-phone"></i>
                    <div className='text'>
                    <h2>لديك سؤال؟</h2>
                    <a href={`tel:${phone.link}`}>
                        {phone.link}
                    </a>
                    </div>
                </div>

                <div className='info'>
                    <i class="las la-envelope"></i>
                    <div className='text'>
                    <h2>أرسل بريدًا إلكترونيًا</h2>
                    <a href={`mailto:${phone.link}`}>
                    {email.link}
                    </a>
                    </div>
                </div>

                <div className='info'>
                    <i class="las la-map-marker"></i>
                    <div className='text'>
                    <h2>زيارتنا الآن</h2>
                    <a href={geolocation.link}>
                        السعودية - الرياض
                    </a>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <div className='col-lg-8 col-md-8 col-sm-6 co-12'>
            <div className='contact-form'>
                <form>
                <div className='inputs'>
                    <div className='input'>
                    <input required type='text' placeholder='اسمك' />
                    </div>

                    <div className='input'>
                    <input required type='email' placeholder='عنوان البريد الإلكتروني' />
                    </div>
                </div>

                <div className='inputs'>
                    <div className='input'>
                    <textarea placeholder='أكتب رسالة' required></textarea>
                    </div>
                </div>

                <button type='submit'>إرسال رسالة <i class="las la-arrow-right"></i></button>
                </form>
            </div>
            </div>
        </div>
        </div>
    </section>
    </>
)
}

export default Contact;

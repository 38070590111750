import './css/products.css';


import { Link as RouterLink,useNavigate,useLocation, useParams } from 'react-router-dom';
import ProductsPageImg from './images/about2.webp';




import React, { useRef, useEffect, useState } from 'react';
import axios from '../config/index';



function ProductsPage(){



    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    
    const [products, setProducts] = useState([])


    const getAllProducts = ()=>{
        axios.post('AllItems', {
            table: 'products',
        })
        .then((res) => {
            if (res.data) {
                setProducts(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

            
    const [Supplier, setSupplier] = useState("");



    const getSupplier = (itemId) => {
        let table = "suppliers";
        var itemId = id;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setSupplier(res.data);
            }
        })
        .catch((err) => console.log(err));
    };


    

    useEffect(() => {

        getAllProducts();
        getSupplier();

    }, []);






    return(
        <>
            
            <section className='page-header'>
                    <div className='overlay'></div>
                    <img  loading='lazy' src={ProductsPageImg}  alt='products-page'/>
                    <div className='page-header-content'>
                        <div className='text'>
                            <h1> منتجات {Supplier.name} </h1>
                            <div className='links'>
                                <RouterLink to="/">
                                    الرئيسية
                                </RouterLink>
                                <i class="las la-arrow-right"></i>
                                <RouterLink to='/about-us'>
                                    West Zaden 
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='products-page'>
                    <div className='container-fluid'>
                        <div className='row'>

                        {products.length ? (
                            products
                                .filter(product => product.supplier_id === Supplier.id)
                                .map(product => (
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12' key={product.id}>
                                    <div className='product'>
                                    {product.img.split(',').length > 0 && (
                                        <img src={`uploads/${product.img.split(',')[0]}`} alt={`product-img-0`} />
                                        )}
                                    <h1>{product.name}</h1>
                                    <h2><span>{Supplier.name}</span> المورد</h2>
                                    <h2>{product.country}</h2>
                                    <RouterLink to={`/product?id=${product.id}`}>تفاصيل المنتج</RouterLink>
                                    </div>
                                </div>
                                ))
                            ) : (
                            <h6 colSpan={5}>تحميل...</h6>
                            )}


                        </div>
                    </div>
                </section>
        </>
    )
}

export default ProductsPage;
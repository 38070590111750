import './css/navbar.css';

import Logo from './images/logo.png';

import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useState,useEffect,useRef  } from "react";
import $ from 'jquery';


import axios from '../config/index';



function Navbar(props) {

    const location = useLocation();



    useEffect(() => {
        // Scroll to the top when the location changes
        window.scrollTo(0, 0);
    }, [location.pathname]); 



    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $("nav").addClass("active");

            $(".nav-elements").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
            $("nav").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                $(".nav-elements").slideUp(400);
                $("nav").removeClass("active");

            });
        }
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);


    const isActiveLink = (path) => {
        return location.pathname === path ? "active" : "";
    };




    const [facebook,setFacebook] = useState("");

    const getFacebook = (itemId) => {
        let table = "social";
        var itemId = 1;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setFacebook(res.data);
            }
        })
        .catch((err) => console.log(err));
    };



    const [instagram,setInstagram] = useState("");



    const getInstagram = (itemId) => {
        let table = "social";
        var itemId = 2;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setInstagram(res.data);
            }
        })
        .catch((err) => console.log(err));
    };



    
    const [twitter,setTwitter] = useState("");



    const getTwitter = (itemId) => {
        let table = "social";
        var itemId = 3;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setTwitter(res.data);
            }
        })
        .catch((err) => console.log(err));
    };


    const [linkedin,setLinkedin] = useState("");



    const getLinkedin = (itemId) => {
        let table = "social";
        var itemId = 4;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setLinkedin(res.data);
            }
        })
        .catch((err) => console.log(err));
    };




    
    const [geolocation, setGeoLocation] = useState("");



    const getLocation = (itemId) => {
        let table = "social";
        var itemId = 6;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setGeoLocation(res.data);
            }
        })
        .catch((err) => console.log(err));
    };



        
    const [email, setEmail] = useState("");



    const getEmail = (itemId) => {
        let table = "social";
        var itemId = 7;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setEmail(res.data);
            }
        })
        .catch((err) => console.log(err));
    };



            
    const [phone, setPhone] = useState("");



    const getPhone = (itemId) => {
        let table = "social";
        var itemId = 8;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setPhone(res.data);
            }
        })
        .catch((err) => console.log(err));
    };

    

    useEffect(() => {

        getFacebook();
        getInstagram();
        getTwitter();
        getLinkedin();
        getLocation();
        getEmail();
        getPhone();


    }, []);



return (
    <>
    <nav className={props.className}>
        <div className='container-fluid'>
        <div className='navbar-content'>
            <div className='logo'>
            <img src={Logo} />
            </div>
            <div className='nav-content'>
            <div className='upper'>
                <div className='address'>
                <a href={`mailto:${email.link}`}>
                    <i class="las la-envelope"></i>
                    {email.link}
                </a>

                <a href={`mailto:${geolocation.link}`}>
                    <i class="las la-map-marker"></i>
                    السعودية - الرياض
                </a>
                </div>

                <div className='links'>
                <a href={facebook.link}>
                    <i class="lab la-facebook-f"></i>
                </a>

                <a href={twitter.link}>
                    <i class="lab la-twitter"></i>
                </a>

                <a href={linkedin.link}>
                    <i class="lab la-linkedin-in"></i>
                </a>

                <a href={instagram.link}>
                    <i class="lab la-instagram"></i>
                </a>
                </div>
            </div>

            <div className='lower'>
                <button id="open-nav"> <i className="las la-bars"></i> </button>
                    <div className="nav-elements">
                <button id="close-nav"> <i className="las la-times"></i> </button>
                <ul>
                    <li>
                    <RouterLink className={isActiveLink("/")} to='/'>
                        الصفحة الرئيسية
                    </RouterLink>
                    </li>

                    <li>
                    <RouterLink className={isActiveLink("/about-us")} to='/about-us'>
                        من نحن
                    </RouterLink>
                    </li>

                    <li>
                    <RouterLink className={isActiveLink("/suppliers")} to='/suppliers'>

                        الموردين
                    </RouterLink>
                    </li>

                    <li>
                    <RouterLink className={isActiveLink("/contact-us")} to='/contact-us'>
                        اتصل بنا
                    </RouterLink>
                    </li>

                    <li>
                    <RouterLink className={isActiveLink("/events")} to='/events'>
                        الفعاليات
                    </RouterLink>
                    </li>

                    <li>
                    <a href="#">
                        الكتالوج
                    </a>
                    </li>

                    <li>
                    <RouterLink className={isActiveLink("/posts")} to='/posts'>
                        الاخبار
                    </RouterLink>
                    </li>

                </ul>
                </div>
            </div>
            </div>

            <div className='call-us'>
            <a href={`tel:${phone.link}`}>
                <i class="las la-phone"></i>
            </a>
            </div>
        </div>
        </div>
    </nav>
    </>
)
}

export default Navbar;

import './css/aboutpage.css';

import LargeImg from './images/ser1_0.webp';
import MediumImg from './images/ser2_1.webp';
import SmallImg from './images/ser6_5.webp';

import React, { useRef, useEffect, useState } from 'react';
import axios from '../config/index';


import { Link as RouterLink } from 'react-router-dom';


import AboutPageImg from './images/about1_0.webp';




import AboutImg from './images/L-1_2.webp';


function AboutPage(){


    const [whatsapp, setWhatsapp] = useState("");



    const getWhatsapp = (itemId) => {
        let table = "social";
        var itemId = 5;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setWhatsapp(res.data);
            }
        })
        .catch((err) => console.log(err));
    };




    
    const [suppliers, setSuppliers] = useState("");



    const getAllSuppliers = ()=>{
        axios.post('AllItems', {
            table: 'suppliers',
        })
        .then((res) => {
            if (res.data) {
                setSuppliers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    

    useEffect(() => {

        getAllSuppliers();
        getWhatsapp();



    }, []);




    return(
        <>

                <section className='page-header'>
                    <div className='overlay'></div>
                    <img  loading='lazy' src={AboutPageImg}  alt='aboutpageimg'/>
                    <div className='page-header-content'>
                        <div className='text'>
                            <h1> عن الشركة </h1>
                            <div className='links'>
                                <RouterLink to="/">
                                    الرئيسية
                                </RouterLink>
                                <i class="las la-arrow-right"></i>
                                <RouterLink to='/about-us'>
                                    عن الشركة
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </section>


            <section className='about-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-images'>

                                <div className='large-image'>
                                    <img src={LargeImg} alt='elateAboutImg' />
                                </div>

                                <div className='medium-image'>
                                    <img src={MediumImg} alt='elateAboutImg' />
                                </div>

                                <div className='small-image'>
                                    <img src={SmallImg} alt='elateAboutImg' />
                                    <div className='text'>
                                        <h1> 3K <span>+</span></h1>
                                        <h2> موردين يمكنك التعامل معهم من خلالنا </h2>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-content'>
                                <h2> <i class="las la-language"></i> مرحبًا بك في وادي الفلاح  </h2>
                                <h1> نحن نؤمن أن كل بذرة تحمل وعد رحلة جميلة </h1>

                                <p> 
                                سواء كنت تزرع حديقة صغيرة على شرفتك، أو مزرعة واسعة، أو أي شيء آخر فيما بينهما، وادي الفلاح هنا لمرافقتك في رحلتك. ندعوك لاستكشاف مجموعتنا المتنوعة من البذور والمنتجات، والانطلاق في مغامرات زراعية جديدة، والتمتع بفرحة مشاهدة أحلامك تتفتح في الواقع. شكرًا لاختيار وادي الفلاح كشريك موثوق في جميع جهودك في الحدائق والزراعة.
                                </p>

                                <div className='benefits'>
                                    <img src={AboutImg} alt="AboutImg" />

                                    <ul>
                                        <li>
                                            <i class="las la-check-circle"></i>
                                            جودة لا تتنازل عنها

                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            تميز الموردين

                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            إرشادات خبيرة

                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            اختيار مدروس

                                        </li>
                                    </ul>
                                </div>

                                <a href={whatsapp.link}>
                                    إعرف المزيد
                                    <i class="las la-arrow-right"></i>
                                </a>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </section>

            <section className='partners'>
                <div className='container-fluid'>
                    <div className='row'>
                        

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='images'>

                                
                            {suppliers.length ? (

                        suppliers.map(supplier => {

                                return (
                                    <>
                                    <img src={`uploads/${supplier.img}`} alt='supplier' />

                                    </>
                                );
                                })): 
                                (
                                        <h6 colSpan={5}>تحميل...</h6>
                                    )}



                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partners-content'>
                                <h2> <i class="las la-university"></i> شركائنا الموثوقين </h2>
                                <h1> لدينا أكثر من <span> 1245+ </span> شركاء حول العالم </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default AboutPage;
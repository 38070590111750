import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ShowButton from '../ShowButton';






function AddEvents(){


        
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);





    const navigate = useNavigate();


    const [event, setEvent] = useState({});
    
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [para, setpara] = useState("");
    const [img, setImg] = useState("");

    const [images, setImages] = useState([]);


    

    const handleChange = (event) => {
        const fileList = event.target.files;
        const imageArray = Array.from(fileList);
        setImages(imageArray);
    };






    const handleSubmit = (event) => {
        event.preventDefault();
        let Loading = document.querySelector(".loading-screen");
        Loading.classList.add("active");
        const data = new FormData();
        data.append("name", name || "");
        data.append("date", date || "");
        data.append("para", para || "");

        if (img) {
            data.append("img", img);
        }

        if (images.length > 0) {
            images.forEach((image) => {
                data.append("images", image);
            });
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        axios
            .post("/add-event", data, config)
            .then((response) => {
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);
            })
            .catch((error) => {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                    alertD.classList.remove("active");
                }, 3000);
                console.log(error);
            });
    };

    axios.defaults.withCredentials = true;



    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }
        
    })
    .catch((err) => console.log(err));

    }




    useEffect(() => {
        getIfLogin();
    }, []);
    




    

    return(
        <>

                    <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>



                <div className='edit-form'>

                    <div className='alert-success'>
                        <h1> تم إضافة البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تسجيل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> إضافة الحدث </h1>

                    <form onSubmit={handleSubmit}>

                        
                    <div className='input'>
                        <label> العنوان  </label>
                        <input  onChange={(event) => setName(event.target.value)} type='text' required="required"  maxLength="250" name='name'/>
                    </div>

                                            
                    <div className='input'>
                        <label> التاريخ  </label>
                        <input  onChange={(event) => setDate(event.target.value)} type='date' required="required"  maxLength="250" name='date'/>
                    </div>


                    
                    <div className='input'>
                        <label> الصورة الرئيسية  </label>
                        <input onChange={(event) => setImg(event.target.files[0])} type='file' name="img"  accept="image/*" />
                    </div>

                    
                    <div className='input'>
                        <label> صور الحدث  </label>
                        <input onChange={handleChange} type='file' name="images" multiple accept="image/*" />
                    </div>



                    <div className='input'>
                        <label> النص   </label>
                        <textarea  onChange={(event) => setpara(event.target.value)} required="required" name='para'></textarea>
                    </div>

    
                
                    <button type='submit'> حفظ </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default AddEvents;
import './css/ad.css';
import { Link as RouterLink, useLocation } from 'react-router-dom';

function Ad() {
return (
    <>
    <section className='ad'>
        <div className='overlay'></div>
        <div className='container-fluid'>
        <div className='ad-content'>
            <div className='text'>
            <i class="las la-seedling"></i>
            <h2>زراعة النجاح</h2>

            <h1>اطلق عنان إمكانيات الطبيعة مع مجموعتنا الاستثنائية من البذور</h1>

            <RouterLink to='/about-us'>
                اكتشف المزيد
                <i class="las la-arrow-right"></i>
            </RouterLink>
            </div>
        </div>
        </div>
    </section>
    </>
)
}

export default Ad;

import './css/landing.css';
import Landingimg1 from './images/L-1_2.webp';


import { useState,useEffect,useRef  } from "react";
import axios from '../config/index';


function Landing() {


    
            
    const [whatsapp, setWhatsapp] = useState("");



    const getWhatsapp = (itemId) => {
        let table = "social";
        var itemId = 5;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setWhatsapp(res.data);
            }
        })
        .catch((err) => console.log(err));
    };

    

    useEffect(() => {

        getWhatsapp();


    }, []);





return (
    <>
    <section className='landing'>

        <div className='landing-content'>
        <div className='overlay'></div>
        <img src={Landingimg1} />
        <h2>نحن ننتج منتجات طبيعية</h2>

        <h1>أفضل أنواع البذور</h1>

        <a href={whatsapp.link}>
            اكتشف المزيد
            <i class="las la-arrow-right"></i>
        </a>

        <div className='facts'>
            <div className='fact'>
            <i class="las la-award"></i>
            <h2>أعلى معايير الجودة</h2>
            </div>

            <div className='fact'>
            <i class="las la-mobile-alt"></i>
            <h2>حلول بيئية ذكية</h2>
            </div>

            <div className='fact'>
            <i class="las la-seedling"></i>
            <h2>منتجات عضوية غذائية</h2>
            </div>
        </div>
        </div>
    </section>
    </>
)
}

export default Landing;

import { Link as RouterLink } from 'react-router-dom';

import ContactUsImg from './images/L-1_2.webp';
import Contact from './Contact';


function ContactUs(){
    return(
        <>
        
        <section className='page-header'>
                    <div className='overlay'></div>
                    <img  loading='lazy' src={ContactUsImg}  alt='contactusimg'/>
                    <div className='page-header-content'>
                        <div className='text'>
                            <h1>  تواصل معنا </h1>
                            <div className='links'>
                                <RouterLink to="/">
                                    الرئيسية
                                </RouterLink>
                                <i class="las la-arrow-right"></i>
                                <RouterLink to='/about-us'>
                                    تواصل معنا
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </section>

                <Contact />

        </>
    )

}

export default ContactUs;
import React, { useState } from 'react';
import './css/posts.css';

function Post({ post }) {
const [showMore, setShowMore] = useState(false);

const toggleShowMore = () => {
    setShowMore(!showMore);
};

return (
    <div className='post'>
    <div className='info'>
        <div>
        <img src={`uploads/${post.user_img}`} alt='logo' />
        <h1>{post.name}</h1>
        </div>
        <h2>{post.date} <i className="las la-globe"></i></h2>
    </div>
    <p>
        {post.para.length > 100 && !showMore
        ? `${post.para.slice(0, 100)}...`
        : post.para}
        {post.para.length > 100 && (
        <button onClick={toggleShowMore}>
            {showMore ? 'اقرا القليل' : 'اقرا المزيد'}
        </button>
        )}
    </p>
    <img src={`uploads/${post.img}`} alt='postimg' />
    </div>
);
}

export default Post;

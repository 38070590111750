import './css/events.css';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import SectionHeader from './SectionHeader';

import React, { useRef, useEffect, useState } from 'react';
import axios from '../config/index';


function Events() {


    
                
    const [events, setEvents] = useState("");



    const getAllEvents = ()=>{
        axios.post('AllItems', {
            table: 'events',
        })
        .then((res) => {
            if (res.data) {
                setEvents(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    

    useEffect(() => {

        getAllEvents();



    }, []);




return (
    <>
    <SectionHeader secheader="من أحداث الماضي" header="آخر الأخبار والفعاليات" />
    <section className='events'>
        <div className='container-fluid'>
        <div className='row'>


        {events.length ? (

            events.map(event => {

            return (
                <>

                    <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='event'>
                                <div className='event-pic'>

                                <RouterLink className='edit' to={`/event?id=${event.id}`}>
                                    <img src={`uploads/${event.img}`} />
                                </RouterLink>
                                <div className='date'>
                                    <span></span>
                                    <span>{event.date}</span>
                                </div>
                                </div>

                                <div className='info'>
                                <div className='user'>
                                    <i class="las la-user"></i>
                                    <span>وادي الفلاح</span>
                                </div>
                                <div className='pics-num'>
                                    <i className="las la-camera"></i>
                                    <span>{Array.isArray(event.images) ? event.images.length : 1}</span>
                                    </div>

                                </div>

                                <h1>{event.name}</h1>

                            </div>
                        </div>


                </>
            );
            })): 
            (
                    <h6 colSpan={5}>تحميل...</h6>
                )}


        </div>
        </div>
    </section>
    </>
)
}

export default Events;

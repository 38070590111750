import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ShowButton from '../ShowButton';






function AddServices(){


        
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);





    const navigate = useNavigate();


    const [service, setService] = useState({});
    
    const [header, setHeader] = useState("");
    const [headeren, setHeaderen] = useState("");
    const [para, setpara] = useState("");
    const [paraen, setparaen] = useState("");

    const [images, setImages] = useState([]);


    

    const handleChange = (event) => {
        const fileList = event.target.files;
        const imageArray = Array.from(fileList); // Convert FileList to a regular array
        setImages(imageArray);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");

        const data = new FormData();


        data.append("header", header || "");
        data.append("header_en", headeren || "");
        data.append("para", para || "");
        data.append("para_en", paraen || "");
        
        if (images.length > 0) {
            images.forEach((image) => {
            data.append("images", image);
            });
        }
    

    
        const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        };
    
        axios
        .post("/add-service", data, config)
        .then((response) => {
            
            Loading.classList.remove("active");

            let alertS = document.querySelector(".alert-success"); 
            alertS.classList.add("active");


            setTimeout(() => {
                alertS.classList.remove("active");
            }, 3000);


        })
        .catch((error) => {
            
            Loading.classList.remove("active");

            let alertD = document.querySelector(".alert-danger"); 
            alertD.classList.add("active");
            setTimeout(() => {
                alertD.classList.remove("active");
            }, 3000);
            console.log(error);
        });
    };
    


    axios.defaults.withCredentials = true;



    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }
        
    })
    .catch((err) => console.log(err));

    }




    useEffect(() => {
        getIfLogin();
    }, []);
    




    

    return(
        <>

                    <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>



                <div className='edit-form'>

                    <div className='alert-success'>
                        <h1> تم إضافة البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تسجيل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> إضافة الخدمة </h1>

                    <form onSubmit={handleSubmit}>

                        
                    <div className='input'>
                        <label> العنوان  </label>
                        <input  onChange={(event) => setHeader(event.target.value)} type='text' required="required"  maxLength="250" name='header'/>
                    </div>

                    
                    <div className='input'>
                        <label> العنوان بالانجليزية  </label>
                        <input  onChange={(event) => setHeaderen(event.target.value)} type='text' required="required"  maxLength="250" name='header_en'/>
                    </div>


                    <div className='input'>
                        <label> النص   </label>
                        <input  onChange={(event) => setpara(event.target.value)} type='text' required="required"  maxLength="500" name='sec_header'/>
                    </div>

                    
                    <div className='input'>
                        <label> النص  بالانجليزية  </label>
                        <input  onChange={(event) => setparaen(event.target.value)} type='text' required="required"  maxLength="500" name='sec_header_en'/>
                    </div>



                    <div className='input'>
                        <label> الصورة  </label>
                        <input onChange={handleChange} type='file' name="images" multiple accept="image/*" />
                    </div>

                
                    <button type='submit'> حفظ </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default AddServices;
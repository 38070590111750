import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditNumbers() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [AllNumbers, setAllNumbers] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        num_1: "",
        num_1_text: "",
        num_2: "",
        num_2_text: "",
        num_3: "",
        num_3_text: "",
        num_4: "",
        num_4_text: "",

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (AllNumbers.id) {
        setFormInputs({
            id:id,
            num_1: AllNumbers.num_1,
            num_1_text: AllNumbers.num_1_text,
            num_2: AllNumbers.num_2,
            num_2_text: AllNumbers.num_2_text,
            num_3: AllNumbers.num_3,
            num_3_text: AllNumbers.num_3_text,
            num_4: AllNumbers.num_4,
            num_4_text: AllNumbers.num_4_text,
        });
        }
    }, [AllNumbers]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const {
                id,
                num_1,
                num_1_text,
                num_2,
                num_2_text,
                num_3,
                num_3_text,
                num_4,
                num_4_text,
            } = formInputs;
        
            if (
                !id ||
                !num_1 ||
                !num_1_text ||
                !num_2 ||
                !num_2_text ||
                !num_3 ||
                !num_3_text ||
                !num_4 ||
                !num_4_text 
            ) {
                console.log(id,num_1,num_1_text,num_2,num_2_text,num_3,num_3_text,
                    num_4,num_4_text)
                return alert("All fields are required");
            }
            
        
            try {
                const response = await axios.post("/update-number", {
                id,
                num_1,
                num_1_text,
                num_2,
                num_2_text,
                num_3,
                num_3_text,
                num_4,
                num_4_text,
                });
            
                if (response.status === 200) {
                // Update AllNumbers state after a successful update
                setAllNumbers((prevNumbers) => ({
                    ...prevNumbers,
                    num_1,
                    num_1_text,
                    num_2,
                    num_2_text,
                    num_3,
                    num_3_text,
                    num_4,
                    num_4_text,
                }));
            
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getNumbers = (itemId) => {
        let table = "numbers";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setAllNumbers(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getNumbers(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getNumbers(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <h1> تعديل الصفحة الرئيسية :  {formInputs.header}</h1>
                    <form onSubmit={handleSubmit}>



                            <div className='input'>
                                <label> الرقم الاول  </label>
                                <input  value={formInputs.num_1}  onChange={handleChange} type='text' required="required"  maxLength="100" name='num_1'/>
                            </div>


                                
                            <div className='input'>
                                <label> نص الرقم الاول  </label>
                                <input  value={formInputs.num_1_text}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_1_text'/>
                            </div>


                            
                            <div className='input'>
                                <label> الرقم الثاني  </label>
                                <input  value={formInputs.num_2}  onChange={handleChange} type='text' required="required"  maxLength="100" name='num_2'/>
                            </div>


                                
                            <div className='input'>
                                <label> نص الرقم الثاني  </label>
                                <input  value={formInputs.num_2_text}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_2_text'/>
                            </div>


                            <div className='input'>
                                <label> الرقم الثالث  </label>
                                <input  value={formInputs.num_3}  onChange={handleChange} type='text' required="required"  maxLength="100" name='num_3'/>
                            </div>


                                
                            <div className='input'>
                                <label> نص الرقم الثالث  </label>
                                <input  value={formInputs.num_3_text}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_3_text'/>
                            </div>


                            
                            <div className='input'>
                                <label> الرقم الرابع  </label>
                                <input  value={formInputs.num_4}  onChange={handleChange} type='text' required="required"  maxLength="100" name='num_4'/>
                            </div>


                                
                            <div className='input'>
                                <label> نص الرقم الرابع  </label>
                                <input  value={formInputs.num_4_text}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_4_text'/>
                            </div>



                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditNumbers;

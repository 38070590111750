import Events from './Events';
import EventspageImg from './images/ser6_5.webp';
import { Link as RouterLink } from 'react-router-dom';


function EventsPage(){

    return(
        <>
        
                <section className='page-header'>
                    <div className='overlay'></div>
                    <img  loading='lazy' src={EventspageImg}  alt='events page img'/>
                    <div className='page-header-content'>
                        <div className='text'>
                            <h1> الفعاليات </h1>
                            <div className='links'>
                                <RouterLink to="/">
                                    الرئيسية
                                </RouterLink>
                                <i class="las la-arrow-right"></i>
                                <RouterLink to='/about-us'>
                                    الفعاليات   
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </section>


                <Events />



        </>
    )
}

export default EventsPage;
import './css/footer.css';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Logo from './images/logo.png';


import { useState,useEffect,useRef  } from "react";

import axios from '../config/index';


function Footer(props) {


    
    
    const [geolocation, setGeoLocation] = useState("");



    const getLocation = (itemId) => {
        let table = "social";
        var itemId = 6;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setGeoLocation(res.data);
            }
        })
        .catch((err) => console.log(err));
    };



        
    const [email, setEmail] = useState("");



    const getEmail = (itemId) => {
        let table = "social";
        var itemId = 7;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setEmail(res.data);
            }
        })
        .catch((err) => console.log(err));
    };



            
    const [phone, setPhone] = useState("");



    const getPhone = (itemId) => {
        let table = "social";
        var itemId = 8;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setPhone(res.data);
            }
        })
        .catch((err) => console.log(err));
    };


    
                
    const [events, setEvents] = useState("");



    const getAllEvents = ()=>{
        axios.post('AllItems', {
            table: 'events',
        })
        .then((res) => {
            if (res.data) {
                setEvents(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    

    useEffect(() => {

        getLocation();
        getEmail();
        getPhone();
        getAllEvents();


    }, []);



return (
    <>
    <footer className={props.className}>
        <div className='overlay'></div>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
            <div className='footer-content'>
                <div className='logo'>
                <img src={Logo} />
                <p>
                    انضم إلى مجتمع من عشاق الزراعة والمحترفين المتشابهين. منصات وسائل التواصل الاجتماعي والمنتديات عبر الإنترنت لدينا توفر منصة للتواصل، ومشاركة التجارب، وتبادل الأفكار، وعرض إنجازاتك في مجال الزراعة.
                </p>
                </div>
            </div>
            </div>

            <div className='col-lg-2 col-md-2 col-sm-6 col-12'>
            <div className='footer-content'>
                <h1>روابط مفيدة</h1>

                <ul>
                <li>
                    <RouterLink  to='/'>
                        الصفحة الرئيسية
                    </RouterLink>
                    </li>

                    <li>
                    <RouterLink to='/about-us'>
                        من نحن
                    </RouterLink>
                    </li>

                    <li>
                    <RouterLink  to='/suppliers'>

                        الموردين
                    </RouterLink>
                    </li>

                    <li>
                    <RouterLink  to='/contact-us'>
                        اتصل بنا
                    </RouterLink>
                    </li>

                    <li>
                    <RouterLink  to='/events'>
                        الفعاليات
                    </RouterLink>
                    </li>

                    <li>
                    <a href="#">
                        الكتالوج
                    </a>
                    </li>

                    <li>
                    <RouterLink  to='/posts'>
                        الاخبار
                    </RouterLink>
                    </li>

                </ul>
            </div>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
            <div className='footer-content'>
                <h1>فعاليات</h1>
                <div className='latest-events'>

                {events.length ? (
                events.slice(0, 2).map(event => (
                    <div className='event' key={event.id}> {/* Assuming each event has a unique id */}
                    <RouterLink className='edit' to={`/event?id=${event.id}`}>
                        <img src={`uploads/${event.img}`} alt={`Event ${event.id}`} />
                    </RouterLink>

                    <div className='text'>
                        <h2>{event.date}</h2>
                        <h1>{event.name}</h1>
                    </div>
                    </div>
                ))
                ) : (
                <h6 colSpan={5}>تحميل...</h6>
                )}

                </div>
            </div>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
            <div className='footer-content'>
                <h1>تواصل معنا</h1>

                <div className='links'>
                <a href={`tel:${phone.link}`}> <i class="las la-phone"></i> +652 151 2112 </a>
                <a href={`mailto:${email.link}`}> <i class="las la-envelope"></i> needhelp@company.com </a>
                <a href={geolocation.link}> <i class="las la-map-marker"></i> السعودية - الرياض </a>
                </div>
            </div>
            </div>
        </div>
        </div>
    </footer>
    </>
)
}

export default Footer;

import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditEvent() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };



    const navigate = useNavigate();
    const [event, setEvent] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        name: "",
        para: "",
        date : "",
        images: null,
        img : null
    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (event.id) {
        setFormInputs({
            name: event.name,
            para: event.para,
            date: event.date,
            images: null,
            img: null,

        });
        }
    }, [event]);

    const handleChange = (e) => {
        handleInputChange();
        const { name, type } = e.target;
    
        if (type === "file") {
            const inputValue = Array.from(e.target.files);
    
            // Check if the input name is "img"
            if (name === "img" && inputValue.length > 0) {
                // If it's the "img" input and a file is selected, set it as a single file
                setFormInputs((prevFormInputs) => ({
                    ...prevFormInputs,
                    [name]: inputValue[0], // Set the selected file directly
                }));
            } else {
                // For other file inputs or when "img" is empty, use an array
                setFormInputs((prevFormInputs) => ({
                    ...prevFormInputs,
                    [name]: inputValue, // Use an array for other file inputs or when "img" is empty
                }));
            }
        } else {
            const { value } = e.target;
            setFormInputs((prevFormInputs) => ({
                ...prevFormInputs,
                [name]: value,
            }));
        }
    
        console.log(formInputs);
    };
    

        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { name, para,date, images,img } = formInputs;
        
            if (!name || !para || !date) {
            return alert("All fields are required");
            }
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("name", name);
            form.append("para", para);
            form.append("date", date);
            
            if (images && images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                form.append("images", images[i]);
                }
            }

            if (img) {
                console.log(img)
                form.append("img", img);
                
            }
        
        
            const response = await axios.post("/update-event", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setEvent((prevEvent) => ({
                ...prevEvent,
                name,
                para,
                date,
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getEvent = (itemId) => {
        let table = "events";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setEvent(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getEvent(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getEvent(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <h1> تعديل الخدمة :  {formInputs.header}</h1>
                    <form onSubmit={handleSubmit}>
                
                        <div className='input'>
                            <label> العنوان  </label>
                            <input  value={formInputs.name}  onChange={handleChange} type='text' required="required"  maxLength="250" name='name'/>
                        </div>


                                            
                        <div className='input'>
                        <label> التاريخ  </label>
                        <input value={formInputs.date}   onChange={handleChange} type='date' required="required"  maxLength="250" name='date'/>
                    </div>


                    
                        <div className='input'>
                        <label> الصورة الرئيسية  </label>
                        <input onChange={handleChange} type='file' name="img"  accept="image/*" />
                    </div>



                        <div className='input'>
                            <label>  صور الحدث   </label>
                            <input onChange={handleChange} type='file' name="images" multiple accept="image/*" />
                        </div>



                        <div className='input'>
                            <label> النص   </label>
                            <textarea value={formInputs.para} onChange={handleChange} required="required" name='para'></textarea>
                        </div>


                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditEvent;

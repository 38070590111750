import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditAbout() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [AllAbout, setAllAbout] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        header: "",
        header_en: "",
        sec_header: "",
        sec_header_en: "",
        para: "",
        para_en: "",
    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (AllAbout.id) {
        setFormInputs({
            id:id,
            header: AllAbout.header,
            header_en: AllAbout.header_en,
            sec_header: AllAbout.sec_header,
            sec_header_en: AllAbout.sec_header_en,
            para: AllAbout.para,
            para_en: AllAbout.para_en,
        });
        }
    }, [AllAbout]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const {
                id,
                sec_header,
                sec_header_en,
                header,
                header_en,
                para,
                para_en,
            } = formInputs;
        
            if (
                !id ||
                !sec_header ||
                !sec_header_en ||
                !header ||
                !header_en ||
                !para ||
                !para_en
            ) {
                return alert("All fields are required");
            }
            
        
            try {
                const response = await axios.post("/update-about", {
                id,
                sec_header,
                sec_header_en,
                header,
                header_en,
                para,
                para_en
                });
            
                if (response.status === 200) {
                // Update AllAbout state after a successful update
                setAllAbout((prevAbout) => ({
                    ...prevAbout,
                    header,
                    header_en,
                    sec_header,
                    sec_header_en,
                    para,
                    para_en
                }));
            
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getAllAbout = (itemId) => {
        let table = "about";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setAllAbout(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getAllAbout(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getAllAbout(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <h1> تعديل عن المركز :  {formInputs.header}</h1>
                    <form onSubmit={handleSubmit}>
                
                            <div className='input'>
                                <label> العنوان  </label>
                                <input value={formInputs.header}  onChange={handleChange} type='text' required="required"  maxLength="250" name='header'/>
                            </div>

                            
                            <div className='input'>
                                <label> العنوان بالانجليزية  </label>
                                <input value={formInputs.header_en}  onChange={handleChange} type='text' required="required"  maxLength="250" name='header_en'/>
                            </div>


                            <div className='input'>
                                <label> العنوان الفرعي  </label>
                                <input  value={formInputs.sec_header}  onChange={handleChange} type='text' required="required"  maxLength="500" name='sec_header'/>
                            </div>

                            
                            <div className='input'>
                                <label> العنوان الفرعي بالانجليزية  </label>
                                <input  value={formInputs.sec_header_en}  onChange={handleChange} type='text' required="required"  maxLength="500" name='sec_header_en'/>
                            </div>


                            
                            <div className='input'>
                                <label> النص  </label>
                                <textarea defaultValue={formInputs.para} name='para' required="required" onChange={handleChange}>
                                    
                                </textarea>
                            </div>

                                    
                            <div className='input'>
                                <label> النص بالانجليزية  </label>
                                <textarea defaultValue={formInputs.para_en} name='para_en' required="required" onChange={handleChange}>
                                    
                                </textarea>
                            </div>


                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditAbout;

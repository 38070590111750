import './css/about.css';

import About1 from './images/about1_0.webp';
import About2 from './images/about2.webp';
import Seeds from './images/seads.png';



import { useState,useEffect,useRef  } from "react";
import axios from '../config/index';



function About() {

                
    const [whatsapp, setWhatsapp] = useState("");



    const getWhatsapp = (itemId) => {
        let table = "social";
        var itemId = 5;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setWhatsapp(res.data);
            }
        })
        .catch((err) => console.log(err));
    };

    

    useEffect(() => {

        getWhatsapp();


    }, []);



return (
    <>
    <section className='about'>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
            <div className='about-text'>
                <h2>مرحبًا بك في وادي الفلاح</h2>
                <h1>وجهتك النهائية للبذور الممتازة والمنتجات المختارة بعناية من موردين موثوقين</h1>

                <i class="lab la-pagelines"></i>

                <h3>مع شغف عميق بالزراعة والبستنة</h3>

                <p>نحن ملتزمون بمساعدتك في تحقيق أحلامك الخضراء. معتمدين على المعرفة والخبرة الواسعة في الصناعة، ووادي الفلاح يقف بجانبك كشريك موثوق به في توفير البذور عالية الجودة والعناصر المختارة بعناية لتلبية احتياجاتك الفريدة.</p>

                <ul>
                <li>
                    <i class="las la-check-circle"></i>
                    جودة لا تتنازل عنها
                </li>

                <li>
                    <i class="las la-check-circle"></i>
                    تميز الموردين
                </li>

                <li>
                    <i class="las la-check-circle"></i>
                    إرشادات خبيرة
                </li>

                <li>
                    <i class="las la-check-circle"></i>
                    اختيار مدروس
                </li>
                </ul>

                <a href={whatsapp.link}>
                اتصل بنا
                <i class="las la-arrow-right"></i>
                </a>

            </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
            <div className='about-images'>
                <img className='small-img' src={About1} />
                <img className='large-img' src={About2} />

                <div className='back-layer'>

                </div>

                <img className='seads' src={Seeds} />
            </div>
            </div>
        </div>
        </div>
    </section>
    </>
)
}

export default About;

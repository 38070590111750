import './css/numbers.css';
import React, { useRef, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from '../config/index';

function CountUpAnimated({ end, isVisible }) {
const props = useSpring({ number: isVisible ? end : 0 });

return (
    <animated.h3>
    {props.number.interpolate(number => Math.floor(number))}
    </animated.h3>
);

}

function Numbers() {


                
    const [numbers, setNumbers] = useState("");



    const GetAllNumbers = ()=>{
        axios.post('AllItems', {
            table: 'numbers',
        })
        .then((res) => {
            if (res.data) {
                setNumbers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    

    useEffect(() => {

        GetAllNumbers();



    }, []);






    
const [isVisible, setIsVisible] = useState(false);
const targetRef = useRef(null);

const handleIntersection = (entries) => {
    entries.forEach(entry => {
    if (entry.isIntersecting) {
        setIsVisible(true);
    }
    });
};

useEffect(() => {
    const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5, // Adjust the threshold as needed
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (targetRef.current) {
    observer.observe(targetRef.current);
    }

    return () => {
    if (targetRef.current) {
        observer.unobserve(targetRef.current);
    }
    };
}, [numbers]);

return (
    <>
    <section className='numbers'>
        <div className='container-fluid'>
        <div className='row'>

        {numbers.length ? (

            numbers.map(number => {

            return (
                <>

                
            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
            <div className='number' ref={targetRef}>
                <i class="las la-leaf"></i>
                <CountUpAnimated end={parseInt(number.num_1)} isVisible={isVisible} precentage="%" />
                <h4>{number.num_1_text}</h4>
            </div>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
            <div className='number'>
                <i class="las la-list-alt"></i>
                <CountUpAnimated end={parseInt(number.num_2)} isVisible={isVisible} precentage="%" />
                <h4>{number.num_2_text}</h4>
            </div>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
            <div className='number'>
                <i class="las la-laugh-beam"></i>
                <CountUpAnimated end={parseInt(number.num_3)} isVisible={isVisible} precentage="%" />
                <h4>{number.num_3_text}</h4>
            </div>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
            <div className='number'>
                <i class="las la-users"></i>
                <CountUpAnimated end={parseInt(number.num_4)} isVisible={isVisible} precentage="%" />
                <h4>{number.num_4_text}</h4>
            </div>
            </div>
                
                </>
            );
            })): 
            (
                    <h6 colSpan={5}>تحميل...</h6>
                )}

    
        </div>
        </div>
    </section>
    </>
);
}

export default Numbers;

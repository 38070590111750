import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditProduct() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };


    


    const navigate = useNavigate();
    const [Products, setProducts] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        name: "",
        supplier_id: "",
        country: "",
        description: "",
        specifications: [{ name: "", value: "" }],
        catalog: null, // Initialize with null
        images: null,
    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (Products.id) {
        setFormInputs({
            name: Products.name,
            supplier_id: Products.supplier_id,
            country: Products.country,
            description: Products.description,
            specifications: Products.specifications,
            catalog: null,
            images: null,
        });
        }
    }, [Products]);


    const handleChange = (e) => {
        handleInputChange();
        const { name, type } = e.target;
    
        if (type === "file") {
            const inputValue = Array.from(e.target.files);
    
            // Check if the input name is "img"
            if (name === "img" && inputValue.length > 0) {
                // If it's the "img" input and a file is selected, set it as a single file
                setFormInputs((prevFormInputs) => ({
                    ...prevFormInputs,
                    [name]: inputValue[0], // Set the selected file directly
                }));
            } else {
                // For other file inputs or when "img" is empty, use an array
                setFormInputs((prevFormInputs) => ({
                    ...prevFormInputs,
                    [name]: inputValue, // Use an array for other file inputs or when "img" is empty
                }));
            }
        } else {
            const { value } = e.target;
            setFormInputs((prevFormInputs) => ({
                ...prevFormInputs,
                [name]: value,
            }));
        }
    
        console.log(formInputs);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let Loading = document.querySelector(".loading-screen");
        Loading.classList.add("active");
    
        const { name, supplier_id, country, description, specifications, catalog, images } = formInputs;
    
        if (!name || !supplier_id || !country || !description || !specifications) {
            return alert("All fields are required");
        }
    
        try {
            const form = new FormData();
            form.append("id", id);
            form.append("name", name);
            form.append("supplier_id", supplier_id);
            form.append("country", country);
            form.append("description", description);
            const specificationsText = specifications.map(spec => `${spec.name}: ${spec.value}`).join('\n');
            form.append("specifications", specificationsText || ""); // Convert to JSON string
            form.append("catalog", catalog); // Use formInputs.catalog
            
            if (images && images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                form.append("images", images[i]);
                }
            }
    
            const response = await axios.post("/update-product", form);
    
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setProducts((prevProduct) => ({
                ...prevProduct,
                name, 
                supplier_id, 
                country, 
                description,
                specifications
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getProduct = (itemId) => {
        let table = "products";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setProducts(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getProduct(id);
                }
            })
            .catch((err) => console.log(err));
    };

    
    const [AllSuppliersContent, setAllSuppliersContent] = useState([]);

    

    const getAllSuppliers = ()=>{
        axios.post('AllItems', {
            table: 'suppliers',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllSuppliersContent(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };


    const handleSpecificationChange = (index, field, value) => {
        const updatedSpecifications = [...formInputs.specifications];
        updatedSpecifications[index][field] = value;
    
        setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            specifications: updatedSpecifications,
        }));
    
        setFormChanged(true); // Trigger form change detection
    };
    
    const handleAddSpecification = () => {
        setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            specifications: [...prevFormInputs.specifications, { name: "", value: "" }],
        }));
    
        setFormChanged(true); // Trigger form change detection
    };
    
    

    useEffect(() => {
        getIfLogin();
        getProduct(id);
        getAllSuppliers();

    }, []);

    useEffect(() => {
        if (Products.id) {
            // Ensure that Products.specifications is a string before splitting
            const specificationsText = Products.specifications && typeof Products.specifications === 'string' ? Products.specifications : "";
            const specificationsArray = specificationsText.split("\n").map((line) => {
                const [name, value] = line.split(":").map((part) => part.trim());
                return { name, value };
            });

            setFormInputs((prevFormInputs) => ({
                ...prevFormInputs,
                name: Products.name,
                supplier_id: Products.supplier_id,
                country: Products.country,
                description: Products.description,
                specifications: Array.isArray(specificationsArray) ? specificationsArray : [{ name: "", value: "" }],
                catalog: null,
                images: null,
            }));
        }
    }, [Products]);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <h1> تعديل الصفحة الرئيسية :  {formInputs.name}</h1>
                    <form onSubmit={handleSubmit}>
                
                            <div className='input'>
                                <label> الاسم  </label>
                                <input  onChange={handleChange} value={formInputs.name} type='text' required="required"  maxLength="250" name='name'/>
                            </div>

                            <div className='input'>
                            <label> اسم المورد  </label>
                            <select onChange={handleChange} required="required" name='supplier_id'>
                                <option value=""> اختر المورد </option>
                                {AllSuppliersContent.length ? (
                                    AllSuppliersContent.map(supplier => (
                                        <option
                                            key={supplier.id}
                                            value={supplier.id}
                                            selected={supplier.id === formInputs.supplier_id} // Check if it matches formInputs.supplier_id
                                        >
                                            {supplier.name}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>جاري التحميل...</option>
                                )}
                            </select>
                        </div>

                            
                                
                            <div className='input'>
                                <label> الدولة  </label>
                                <input  value={formInputs.country}  onChange={handleChange} type='text' required="required"  maxLength="250" name='country'/>
                            </div>

                            
                            <div className='input'>
                                <label>  الوصف  </label>
                                <textarea  value={formInputs.description}   onChange={handleChange} required="required" name='description'></textarea>
                            </div>


                            <div className='input'>
                                <label> الكاتالوج  </label>
                                <input onChange={handleChange} type='file' name="catalog" accept=".pdf"/>
                            </div>


                            <div className='input'>
                                <label> صور المنتج  </label>
                                <input onChange={handleChange} type='file' name="images" multiple accept="image/*" />
                            </div>

                            <div className="input">
                            <label>مواصفات المنتج</label>
                            {formInputs.specifications.map((specification, index) => (
                            <div key={index}>
                                <input
                                    type="text"
                                    value={specification.name}
                                    onChange={(e) => handleSpecificationChange(index, "name", e.target.value)}
                                    required="required"
                                    maxLength="250"
                                    placeholder="اسم المواصفة"
                                />
                                <input
                                    type="text"
                                    value={specification.value}
                                    onChange={(e) => handleSpecificationChange(index, "value", e.target.value)}
                                    required="required"
                                    maxLength="250"
                                    placeholder="قيمة المواصفة"
                                />
                            </div>
                        ))}
                        </div>

                        <button type="button" onClick={handleAddSpecification}>
                            إضافة صفة
                        </button>

                        <button disabled={!formChanged} type='submit'> تحديث </button>

                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditProduct;

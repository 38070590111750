import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import Landing from '../Landing';




function AllProducts(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllProducts, setAllProducts] = useState([]);

    axios.defaults.withCredentials = true;





    const getAllProducts = ()=>{
        axios.post('AllItems', {
            table: 'products',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllProducts(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'products',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                getAllProducts();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };




useEffect(() => {

    getAllProducts();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <Link className='new-item' to='/add-product'> +  منتج جديد</Link>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>الصورة</th>
                            <th> الاسم </th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllProducts.length ? (

                        AllProducts.map(product => {

                        return (
                            <tr key={product.id}>
                            <td><h6>{product.id}</h6></td>

                            <td>
                            {product.img.split(',').length > 0 && (
                            <img src={`uploads/${product.img.split(',')[0]}`} alt={`product-img-0`} />
                            )}
                            </td>
                            <td><h6>{product.name}</h6></td>

                            <td>
                                <Link className='edit' to={`/edit-product?id=${product.id}`}> <i class="las la-edit"></i> </Link>
                            </td>

                            
                            <td>
                                <button className='delete' onClick={() => deleteItem(product.id)}> <i class="las la-trash-alt"></i> </button>
                            </td>

                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllProducts;
import './css/services.css';

import services1 from './images/ser1_0.webp';
import services2 from './images/ser2_1.webp';
import services3 from './images/ser3_2.webp';
import services4 from './images/ser4_3.webp';
import services5 from './images/ser5_4.webp';
import services6 from './images/ser6_5.webp';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, EffectCube, EffectCoverflow, Navigation, Pagination } from 'swiper';
import SectionHeader from './SectionHeader';





import { useState,useEffect,useRef  } from "react";
import axios from '../config/index';



function Services() {





                
    const [whatsapp, setWhatsapp] = useState("");



    const getWhatsapp = (itemId) => {
        let table = "social";
        var itemId = 5;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setWhatsapp(res.data);
            }
        })
        .catch((err) => console.log(err));
    };

    

    useEffect(() => {

        getWhatsapp();


    }, []);



return (
    <>
    <SectionHeader secheader="خدماتنا" header="التزامنا بالتميز يمتد" />
    <section id="services" className='services'>
        <div className='container-fluid'>
        <div className='services-content'>
            <Swiper grabCursor={true} slidesPerView={'auto'} navigation={{
            nextEl: '.next-btn',
            prevEl: '.prev-btn',
            }} pagination={true}
            modules={[Autoplay, EffectCoverflow, EffectCube, Navigation, Pagination]}
            >
            <SwiperSlide>
                <div className='service'>
                <img src={services1} />
                <div className='text'>
                    <div className='top'>
                    <div className='left'>
                        <i class="lab la-pagelines"></i>
                    </div>
                    <div className='right'>
                        <h1> مجموعة بذور ممتازة </h1>
                    </div>
                    </div>
                    <p>
                    استكشف مجموعتنا الواسعة من البذور الممتازة، التي تم اختيارها بعناية من موردين موثوقين حول العالم. من الزهور الزاهية إلى الخضروات القوية، تلبي مجموعتنا المتنوعة احتياجات كل نوع من الحدائق. يتم اختيار كل بذرة بعناية لتزويدك بأفضل أساس ممكن لمشروعاتك الزراعية.
                    </p>
                    <a href={whatsapp.link}>
                    اتصل بنا <i class="las la-arrow-right"></i>
                    </a>
                </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='service'>
                <img src={services2} />
                <div className='text'>
                    <div className='top'>
                    <div className='left'>
                        <i class="las la-seedling"></i>
                    </div>
                    <div className='right'>
                        <h1> استشارات مخصصة </h1>
                    </div>
                    </div>
                    <p>
                    فريق خبراء البستنة لدينا متاح للاستشارات المخصصة. سواء كنت مبتدئًا تبحث عن توجيه أو مزارعًا مخضرمًا تتطلع إلى رفع مهاراتك إلى المستوى التالي، نقدم نصائح مخصصة ونصائح لزراعتك وحلولًا لضمان نجاح مشاريعك البستانية.
                    </p>
                    <a href={whatsapp.link}>
                    اتصل بنا <i class="las la-arrow-right"></i>
                    </a>
                </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='service'>
                <img src={services3} />
                <div className='text'>
                    <div className='top'>
                    <div className='left'>
                        <i class="las la-object-group"></i>
                    </div>
                    <div className='right'>
                        <h1> تشكيلة منتجات مختارة بعناية </h1>
                    </div>
                    </div>
                    <p>
                    اكتشف تشكيلتنا المدروسة بعناية من المنتجات المصممة لتعزيز تجربتك في البستنة. من الأدوات الحديثة إلى اللوازم الصديقة للبيئة، قمنا باختيار العناصر التي تكمل رحلتك في البستنة وتسهم في نجاحك.
                    </p>
                    <a href={whatsapp.link}>
                    اتصل بنا <i class="las la-arrow-right"></i>
                    </a>
                </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='service'>
                <img src={services4} />
                <div className='text'>
                    <div className='top'>
                    <div className='left'>
                        <i class="las la-sort-amount-up-alt"></i>
                    </div>
                    <div className='right'>
                        <h1> الطلب بالجملة للمحترفين </h1>
                    </div>
                    </div>
                    <p>
                    بالنسبة للمزارعين التجاريين والمزارعين ومحترفي تصميم الحدائق، نقدم خيارات الطلب بالجملة. عملية سلسة تضمن لك الحصول على الكمية والجودة التي تحتاجها للقيام بمشاريع بأكبر مقياس بثقة.
                    </p>
                    <a href={whatsapp.link}>
                    اتصل بنا <i class="las la-arrow-right"></i>
                    </a>
                </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='service'>
                <img src={services5} />
                <div className='text'>
                    <div className='top'>
                    <div className='left'>
                        <i class="las la-school"></i>
                    </div>
                    <div className='right'>
                        <h1> موارد تعليمية </h1>
                    </div>
                    </div>
                    <p>
                    احصل على ثروة من الموارد التعليمية على موقعنا على الويب، بما في ذلك دليل الزراعة، ونصائح لانبات البذور، ومقالات معمقة حول مواضيع البستنة المتنوعة. نحن نؤمن بأن المعرفة هي مفتاح النجاح في الزراعة، ونحن ملتزمون بتزويدك بالمعلومات التي تحتاجها لتحقيق التفوق.
                    </p>
                    <a href={whatsapp.link}>
                    اتصل بنا <i class="las la-arrow-right"></i>
                    </a>
                </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='service'>
                <img src={services6} />
                <div className='text'>
                    <div className='top'>
                    <div className='left'>
                        <i class="las la-tag"></i>
                    </div>
                    <div className='right'>
                        <h1> عروض حصرية </h1>
                    </div>
                    </div>
                    <p>
                    ابق محدثًا على عروضنا الحصرية وعروضنا الخاصة، التي تهدف إلى جعل تجربتك في البستنة أكثر مكافأة. يتلقى مشتركو النشرة الإخبارية لدينا إشعارات في الوقت المناسب حول التخفيضات والعروض الموسمية.
                    </p>
                    <a href={whatsapp.link}>
                    اتصل بنا <i class="las la-arrow-right"></i>
                    </a>
                </div>
                </div>
            </SwiperSlide>
            </Swiper>
        </div>
        </div>
    </section>
    </>
)
}

export default Services;

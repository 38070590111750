import './App.css';
import { Routes, Route,useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import Services from './components/Services';
import Categories from './components/Categories';
import Ad from './components/Ad';
import Numbers from './components/Numbers';
import Events from './components/Events';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AboutPage from './components/AboutPage';
import SuppliersPage from './components/SuppliersPage';
import ProductsPage from './components/Products';
import ProductPage from './components/ProductPage';
import EventsPage from './components/EventsPage';
import EventPage from './components/EventPage';
import ContactUs from './components/ContactUs';
import Posts from './components/Posts';







// Admin Pages


import SideNav from "./components/Admin/SideNav";
import LoginAdmin from "./components/Admin/LoginAdmin";


import SearchBar from "./components/Admin/SearchBar";
import Dashboard from "./components/Admin/Dashboard";
import AllUsersPage from "./components/Admin/AllUsersPage";
import EditUser from "./components/Admin/EditUser";
import AddUser from "./components/Admin/AddUser";

import AllLandingSlide from './components/Admin/AllLandingSlide';
import EditLandingSlide from './components/Admin/EditLandingSlide';
import AddLandingSlide from './components/Admin/AddLandingSlide';

import AllServices from './components/Admin/AllServices';
import AddServices from './components/Admin/AddServices';
import EditServices from './components/Admin/EditServices';

import AllNumbers from './components/Admin/AllNumbers';
import EditNumbers from './components/Admin/EditNumbers';

import AllBenefits from './components/Admin/AllBenefits';
import EditBenefits from './components/Admin/EditBenefits';
import AllVideos from './components/Admin/AllVideos';
import AddVideos from './components/Admin/AddVideos';
import EditVideos from './components/Admin/EditVideos';
import AllTimeTables from './components/Admin/AllTimeTables';
import EditTimeTables from './components/Admin/EditTimeTables';
import AddSmallService from './components/Admin/AddSmallServices';
import AllSmallServices from './components/Admin/AllSmallServices';
import EditSmallSerivces from './components/Admin/EditSmallServices';

import AddGallery from './components/Admin/AddGallery';
import AllGalleryPage from './components/Admin/AllGalleryPage';
import EditGallery from './components/Admin/EditGallery';


import EditAbout from './components/Admin/EditAbout';
import AllAbout from './components/Admin/AllAbout';


import EditSocial from './components/Admin/EditSocial';
import AllSocialPage from './components/Admin/AllSocialPage';





import AddEvents from './components/Admin/AddEvents';
import EditEvent from './components/Admin/EditEvent';

import AllEvents from './components/Admin/AllEvents';





import AddSuppliers from './components/Admin/AddSuppliers';
import EditSuppliers from './components/Admin/EditSuppliers';
import AllSuppliers from './components/Admin/AllSuppliers';


import AllProducts from './components/Admin/AllProducts';
import AddProducts from './components/Admin/AddProducts';
import EditProducts from './components/Admin/EditProducts';
import AddPosts from './components/Admin/AddPosts';
import EditPosts from './components/Admin/EditPosts';
import AllPosts from './components/Admin/AllPosts';






function App() {


        
    const [sessionExists, setSessionExists] = useState("");
    const servicesRef = useRef(0);
    const location = useLocation();
    const hideHeaderFooter =
    location.pathname === "/dashboard" ||
    location.pathname === "/all-users" ||
    location.pathname === "/all-waiting-users" ||
    location.pathname === "/edit-user" ||
    location.pathname === "/add-user"||
    location.pathname === "/all-landing-slide"||
    location.pathname === "/add-landing-slide"||
    location.pathname === "/edit-landing-slide"||
    location.pathname === "/all-services"||
    location.pathname === "/add-service"||
    location.pathname === "/edit-service"||
    location.pathname === "/all-numbers"||
    location.pathname === "/edit-number"||
    location.pathname === "/all-benefits"||
    location.pathname === "/edit-benefit"||
    location.pathname === "/all-videos"||
    location.pathname === "/add-video"||
    location.pathname === "/edit-video"||
    location.pathname === "/all-time-tables"||
    location.pathname === "/edit-time-table"||
    location.pathname === "/all-small-services"||
    location.pathname === "/add-small-service"||
    location.pathname === "/edit-small-service"||
    location.pathname === "/all-gallery"||
    location.pathname === "/add-gallery"||
    location.pathname === "/edit-gallery" ||   
    location.pathname === "/all-about"||
    location.pathname === "/edit-about"||
    location.pathname === "/all-social"||
    location.pathname === "/all-events"||
    location.pathname === "/add-event"||
    location.pathname === "/edit-event"||
    location.pathname === "/all-suppliers"||
    location.pathname === "/add-supplier"||
    location.pathname === "/edit-supplier"||
    location.pathname === "/all-products"||
    location.pathname === "/add-product"||
    location.pathname === "/edit-product"||
    location.pathname === "/all-posts"||
    location.pathname === "/add-post"||
    location.pathname === "/edit-post"||


    location.pathname === "/edit-social";



return (


    
    <div className="App">

<SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        />



            <Navbar className={!hideHeaderFooter ? "" : "hide"}/>


            <Routes >

            
            <Route path="/" element={
                    <>
                        <Landing />
                        <About />
                        <Services />
                        <Categories />
                        <Ad />
                        <Numbers />
                        <Events />
                        <Contact />

                    </>
                } />



            <Route path="/about-us" element={
                    <>
                        <AboutPage />
                        <Services />

                    </>
                } />


                
                <Route path="/suppliers" element={
                    <>
                        <SuppliersPage />

                    </>
                } />


                <Route path="/supplier-products" element={
                    <>
                        <ProductsPage />

                    </>
                } />



                <Route path="/product" element={
                    <>
                        <ProductPage />

                    </>
                } />

                
                <Route path="/events" element={
                    <>
                        <EventsPage />

                    </>
                } />

                
                    
                <Route path="/event" element={
                    <>
                        <EventPage />

                    </>
                } />


                    
                <Route path="/contact-us" element={
                    <>
                        <ContactUs />

                    </>
                } />


                                    
                <Route path="/posts" element={
                    <>
                        <Posts />

                    </>
                } />




                
            <Route path="/admin-login"element={<><LoginAdmin sessionExists={sessionExists} setSessionExists={setSessionExists} /></>}/>
            <Route path="/dashboard"element={ <><Dashboard /> </>}/>
            <Route path="/all-users" element={ <><AllUsersPage /> </>}/>
            <Route path="/edit-user" element={<><EditUser /></>} />
            <Route path="/add-user" element={<><AddUser /></>}/>
            <Route path="/all-users"element={<><AllUsersPage /></> }/>
            <Route path="/all-landing-slide"element={<><AllLandingSlide /></> }/>
            <Route path="/add-landing-slide"element={<><AddLandingSlide /></> }/>
            <Route path="/edit-landing-slide"element={<><EditLandingSlide /></> }/>
            <Route path="/all-services"element={<><AllServices /></> }/>
            <Route path="/add-service"element={<><AddServices /></> }/>
            <Route path="/edit-service"element={<><EditServices /></> }/>
            <Route path="/edit-number"element={<><EditNumbers /></> }/>
            <Route path="/all-numbers"element={<><AllNumbers /></> }/>
            <Route path="/edit-benefit"element={<><EditBenefits /></> }/>
            <Route path="/all-benefits"element={<><AllBenefits /></> }/>

            <Route path="/all-videos"element={<><AllVideos /></> }/>
            <Route path="/add-video"element={<><AddVideos /></> }/>
            <Route path="/edit-video"element={<><EditVideos /></> }/>

            <Route path="/all-time-tables"element={<><AllTimeTables /></> }/>
            <Route path="/edit-time-table"element={<><EditTimeTables /></> }/>

            <Route path="/add-small-service"element={<><AddSmallService /></> }/>
            <Route path="/all-small-services"element={<><AllSmallServices /></> }/>
            <Route path="/edit-small-service"element={<><EditSmallSerivces /></> }/>

            <Route path="/add-gallery"element={<><AddGallery /></> }/>
            <Route path="/all-gallery"element={<><AllGalleryPage /></> }/>
            <Route path="/edit-gallery"element={<><EditGallery /></> }/>

            <Route path="/all-about" element={<><AllAbout /></> }/>
            <Route path="/edit-about" element={<><EditAbout /></> }/>


            <Route path="/all-social" element={<><AllSocialPage /></> }/>
            <Route path="/edit-social" element={<><EditSocial /></> }/>




            <Route path="/all-events" element={<><AllEvents /></> }/>
            <Route path="/edit-event" element={<><EditEvent /></> }/>
            <Route path="/add-event" element={<><AddEvents /></> }/>



            <Route path="/all-suppliers" element={<><AllSuppliers /></> }/>
            <Route path="/edit-supplier" element={<><EditSuppliers /></> }/>
            <Route path="/add-supplier" element={<><AddSuppliers /></> }/>

            <Route path="/all-products" element={<><AllProducts /></> }/>
            <Route path="/add-product" element={<><AddProducts /></> }/>
            <Route path="/edit-product" element={<><EditProducts /></> }/>



            <Route path="/all-posts" element={<><AllPosts /></> }/>
            <Route path="/add-post" element={<><AddPosts /></> }/>
            <Route path="/edit-post" element={<><EditPosts /></> }/>



        </Routes>
        <Footer className={!hideHeaderFooter ? "" : "hide"}/>

    </div>
);
}

export default App;

import './css/eventpage.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Link as RouterLink,useNavigate,useLocation, useParams } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, EffectCube, EffectCoverflow, Navigation, Pagination } from 'swiper';





import React, { useRef, useEffect, useState } from 'react';
import axios from '../config/index';


function EventPage(){


    
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');




    
            
    const [event, setEvent] = useState("");



    const getEvent = (itemId) => {

        let table = "events";
        var itemId = id;
        axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
                setEvent(res.data);
            }
        })
        .catch((err) => console.log(err));
    };




    useEffect(() => {

        getEvent();

    }, []);
    const [eventImages, setEventImages] = useState([]);



    useEffect(() => {
        if (event.images) {
        // Split the comma-separated text into an array
        const imageArray = event.images.split(',');
        setEventImages(imageArray);
        }
    }, [event]);
    

    return(
        <>  
        
            <section className='event-page'>
                <div className='container'>
                    <div className='row'>

                        <div className='header'>
                            <h2> <i class="las la-globe"></i> {event.date}</h2>

                            <h1> {event.name} </h1>
                        </div>


                        <div className='images-slider'>
                            <Swiper grabCursor={true} slidesPerView={'auto'} navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            }} pagination={true}
                            modules={[Autoplay, EffectCoverflow, EffectCube, Navigation, Pagination]}
                            >
                            {eventImages.map((image, index) => (
                                    <SwiperSlide key={index}>
                                    <img src={`uploads/${image}`} alt={`gallery image ${index}`} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>


                        <div className='event-description'>
                            <p>
                            {event.para}
                        
                            </p>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default EventPage;
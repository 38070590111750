import './css/suppliers.css';

import { Link as RouterLink } from 'react-router-dom';
import SubliersPageImg from './images/about2.webp';

import React, { useRef, useEffect, useState } from 'react';
import axios from '../config/index';


function SuppliersPage(){


    
    
    const [suppliers, setSuppliers] = useState("");



    const getAllSuppliers = ()=>{
        axios.post('AllItems', {
            table: 'suppliers',
        })
        .then((res) => {
            if (res.data) {
                setSuppliers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    

    useEffect(() => {

        getAllSuppliers();

    }, []);




    return(
        <>
            
            <section className='page-header'>
                    <div className='overlay'></div>
                    <img  loading='lazy' src={SubliersPageImg}  alt='aboutpageimg'/>
                    <div className='page-header-content'>
                        <div className='text'>
                            <h1> الموردين </h1>
                            <div className='links'>
                                <RouterLink to="/">
                                    الرئيسية
                                </RouterLink>
                                <i class="las la-arrow-right"></i>
                                <RouterLink to='/about-us'>
                                    الموردين
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </section>

            <section className='suppliers-page'>
                <div className='container-fluid'>
                    <div className='row'>

                            
                    {suppliers.length ? (

                        suppliers.map(supplier => {

                        return (
                            <>
                            
                                <div className='col-lg-2 col-md-3 col-sm-6 col-12'>
                                    <RouterLink to={`/supplier-products?id=${supplier.id}`}>
                                        <div className='supplier'>
                                            <img src={`uploads/${supplier.img}`} alt="supplier logo" />
                                            <h1> {supplier.name} </h1>
                                        </div>
                                    </RouterLink>
                                </div>

                            </>
                        );
                        })): 
                        (
                                <h6 colSpan={5}>تحميل...</h6>
                            )}

                    </div>
                </div>
            </section>
        
        </>
    )
}

export default SuppliersPage;
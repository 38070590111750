import React, { useEffect,useState } from 'react';
import './css/posts.css';

import Post from './Post';

import axios from '../config/index';


function Posts() {


    
    const [posts, setPosts] = useState([])


    const getAllPosts = ()=>{
        axios.post('AllItems', {
            table: 'posts',
        })
        .then((res) => {
            if (res.data) {
                setPosts(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    

    useEffect(() => {

        getAllPosts();

    }, []);






    // Fetch posts from API and store them in a posts array


    return (
    <>
        <section className='posts'>
        <div className='container'>
            <div className='row'>
            {posts.map((post) => (
                <div
                key={post.id}
                className='col-lg-12 col-md-12 col-sm-12 col-12'
                >
                <Post post={post} />
                </div>
            ))}
            </div>
        </div>
        </section>
    </>
    );
}

export default Posts;
import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import Landing from '../Landing';




function AllNumbers(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllNumbers, setAllNumbers] = useState([]);

    axios.defaults.withCredentials = true;





    const getAllNumbers = ()=>{
        axios.post('AllItems', {
            table: 'numbers',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllNumbers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'numbers',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                getAllNumbers();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };




useEffect(() => {

    getAllNumbers();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th> الرقم  </th>
                            <th> النص </th>

                            <th> الرقم  </th>
                            <th> النص </th>


                            <th> الرقم  </th>
                            <th> النص </th>

                            <th> الرقم  </th>
                            <th> النص </th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllNumbers.length ? (

                    AllNumbers.map(number => {

                        return (
                            <tr key={number.id}>
                            <td><h6>{number.id}</h6></td>

                            <td><h6>{number.num_1}</h6></td>
                            <td><h6>{number.num_1_text}</h6></td>


                            <td><h6>{number.num_2}</h6></td>
                            <td><h6>{number.num_2_text}</h6></td>


                            <td><h6>{number.num_3}</h6></td>
                            <td><h6>{number.num_3_text}</h6></td>


                            <td><h6>{number.num_4}</h6></td>
                            <td><h6>{number.num_4_text}</h6></td>




                            <td>
                                <Link className='edit' to={`/edit-number?id=${number.id}`}> <i class="las la-edit"></i> </Link>
                            </td>

                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllNumbers;
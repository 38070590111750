import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ShowButton from '../ShowButton';






function AddProducts(){


        
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);





    const navigate = useNavigate();

    
    const [name, setName] = useState("");
    const [supplier_id, setSupplierId] = useState("");
    const [country, setCountry] = useState("");
    const [description, setDescription] = useState("");
    const [specifications, setSpecifications] = useState([{ name: "", value: "" }]);

    const [catalog, setCatalog] = useState("");
    const [images, setImages] = useState([]);

    const [AllSuppliersContent, setAllSuppliersContent] = useState([]);

    const handleChange = (event) => {
        const fileList = event.target.files;
        const imageArray = Array.from(fileList);
        setImages(imageArray);
    };
    



    const handleAddSpecification = () => {
        setSpecifications([...specifications, { name: "", value: "" }]);
    };

    const handleSpecificationChange = (index, field, value) => {
        const updatedSpecifications = [...specifications];
        updatedSpecifications[index][field] = value;
        setSpecifications(updatedSpecifications);
    };



    const getAllSuppliers = ()=>{
        axios.post('AllItems', {
            table: 'suppliers',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllSuppliersContent(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    const handleSubmit = (event) => {
        event.preventDefault();
    
        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");
    
        const data = new FormData();
    
        data.append("name", name || "");
        data.append("supplier_id", supplier_id || "");
        data.append("country", country || "");
        data.append("description", description || "");
    
        // Serialize the specifications array to text
        const specificationsText = specifications.map(spec => `${spec.name}: ${spec.value}`).join('\n');
        data.append("specifications", specificationsText || "");
    
        if (catalog) {
            data.append("catalog", catalog);
        }
        
        
        if (images.length > 0) {
            images.forEach((image) => {
                data.append("images", image);
            });
        }

    
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
    
        axios
        .post("/add-product", data, config)
        .then((response) => {
            
            Loading.classList.remove("active");
    
            let alertS = document.querySelector(".alert-success"); 
            alertS.classList.add("active");
    
            setTimeout(() => {
                alertS.classList.remove("active");
            }, 3000);
    
        })
        .catch((error) => {
            
            Loading.classList.remove("active");
    
            let alertD = document.querySelector(".alert-danger"); 
            alertD.classList.add("active");
            setTimeout(() => {
                alertD.classList.remove("active");
            }, 3000);
            console.log(error);
        });
    };
    

    axios.defaults.withCredentials = true;



    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }
        
    })
    .catch((err) => console.log(err));

    }




    useEffect(() => {
        getIfLogin();
        getAllSuppliers();
    }, []);
    




    

    return(
        <>

                    <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>



                <div className='edit-form'>

                    <div className='alert-success'>
                        <h1> تم إضافة البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تسجيل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> إضافة منتج </h1>

                    <form onSubmit={handleSubmit}>

                        
                    <div className='input'>
                        <label> الاسم  </label>
                        <input  onChange={(event) => setName(event.target.value)} type='text' required="required"  maxLength="250" name='name'/>
                    </div>

                    
                    <div className='input'>
                        <label> اسم المورد  </label>
                        <select  onChange={(event) => setSupplierId(event.target.value)}  required="required" name='supplier_id'>
                            <option value=""> اختر المورد </option>
                            {AllSuppliersContent.length ? (

                            AllSuppliersContent.map(supplier => {

                                return (
                                    <option value={supplier.id}>{supplier.name}</option>
                                );
                                })): 
                                (
                                        <option>تحميل...</option>
                                    )}
                        </select>
                    </div>

                    
                        
                    <div className='input'>
                        <label> الدولة  </label>
                        <input  onChange={(event) => setCountry(event.target.value)} type='text' required="required"  maxLength="250" name='country'/>
                    </div>

                    
                    <div className='input'>
                        <label>  الوصف  </label>
                        <textarea  onChange={(event) => setDescription(event.target.value)} required="required" name='description'></textarea>
                    </div>


                    <div className='input'>
                        <label> الكاتالوج  </label>
                        <input onChange={(event) => setCatalog(event.target.files[0])} type='file' name="catalog" accept=".pdf"/>
                    </div>

                    
                    <div className='input'>
                        <label> صور المنتج  </label>
                        <input
                            onChange={handleChange}
                            type="file"
                            name="images"
                            multiple  // Allow multiple image selection
                            accept="image/*"
                            />                    
                        </div>



                        <div className="input">
                            <label>اسم الصفة</label>
                                {specifications.map((specification, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    value={specification.name}
                                    onChange={(e) => handleSpecificationChange(index, "name", e.target.value)}
                                    required="required"
                                    maxLength="250"
                                />
                            ))}
                        </div>

                        <div className="input">
                            <label>قيمة الصفة</label>
                            {specifications.map((specification, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    value={specification.value}
                                    onChange={(e) => handleSpecificationChange(index, "value", e.target.value)}
                                    required="required"
                                    maxLength="250"
                                />
                            ))}
                        </div>

                        <button type="button" onClick={handleAddSpecification}>
                            إضافة صفة
                        </button>


                
                    <button type='submit'> حفظ </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default AddProducts;
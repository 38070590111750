import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ShowButton from '../ShowButton';






function AddSmallService(){


        
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);





    const navigate = useNavigate();


    const [smallServices, setSmallServices] = useState({});
    
    const [name, setname] = useState("");
    const [name_en, setnameen] = useState("");
    const [para, setpara] = useState("");
    const [para_en, setparaen] = useState("");

    const [img, setImg] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();

        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");

        const data = new FormData();


        data.append("name", name || "");
        data.append("name_en", name_en || "");
        data.append("para", para || "");
        data.append("para_en", para_en || "");

        if (img) {
            data.append("image", img);
        }
    
    
        const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        };
    
        axios
        .post("/add-small-service", data, config)
        .then((response) => {
            
            Loading.classList.remove("active");

            let alertS = document.querySelector(".alert-success"); 
            alertS.classList.add("active");


            setTimeout(() => {
                alertS.classList.remove("active");
            }, 3000);


        })
        .catch((error) => {
            
            Loading.classList.remove("active");

            let alertD = document.querySelector(".alert-danger"); 
            alertD.classList.add("active");
            setTimeout(() => {
                alertD.classList.remove("active");
            }, 3000);
            console.log(error);
        });
    };
    


    axios.defaults.withCredentials = true;



    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }
        
    })
    .catch((err) => console.log(err));

    }




    useEffect(() => {
        getIfLogin();
    }, []);
    




    

    return(
        <>

                    <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>



                <div className='edit-form'>

                    <div className='alert-success'>
                        <h1> تم إضافة البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تسجيل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> إضافة خدمات مصغرة </h1>

                    <form onSubmit={handleSubmit}>

                        
                    <div className='input'>
                        <label> العنوان  </label>
                        <input  onChange={(event) => setname(event.target.value)} type='text' required="required"  maxLength="250" name='name'/>
                    </div>

                    
                    <div className='input'>
                        <label> العنوان بالانجليزية  </label>
                        <input  onChange={(event) => setnameen(event.target.value)} type='text' required="required"  maxLength="250" name='name_en'/>
                    </div>


                    <div className='input'>
                        <label>  النص  </label>
                        <input  onChange={(event) => setpara(event.target.value)} type='text' required="required"  maxLength="500" name='para'/>
                    </div>

                    
                    <div className='input'>
                        <label>  النص بالانجليزية  </label>
                        <input  onChange={(event) => setparaen(event.target.value)} type='text' required="required"  maxLength="500" name='para_en'/>
                    </div>



                    <div className='input'>
                        <label> الصورة  </label>
                        <input onChange={(event) => setImg(event.target.files[0])} type='file' name="image" accept="image/*"/>
                    </div>

                
                    <button type='submit'> حفظ </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default AddSmallService;
import React, { useEffect, useState } from 'react';
import axios from '../config/index';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import productImg from './images/products/1.png';
import './css/product.css';

import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cube';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,EffectCube,EffectCoverflow,Navigation,Pagination} from 'swiper';

function ProductPage() {
const navigate = useNavigate();
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const id = queryParams.get('id');

const [product, setProduct] = useState("");
const [supplier, setSupplier] = useState("");
const [whatsapp, setWhatsapp] = useState("");
const [specifications, setSpecifications] = useState([]);

const getProduct = (itemId) => {
    let table = "products";
    var itemId = id;
    axios.post('get-item', { itemId, table })
    .then((res) => {
        if (res.data.message) {
        // Handle the case when the team data is not found
        } else {
        setProduct(res.data);
        }
    })
    .catch((err) => console.log(err));
};

const getSupplier = (itemId) => {
    let table = "suppliers";
    var itemId = itemId;
    axios.post('get-item', { itemId, table })
    .then((res) => {
        if (res.data.message) {
        // Handle the case when the team data is not found
        } else {
        setSupplier(res.data);
        }
    })
    .catch((err) => console.log(err));
};

const getWhatsapp = (itemId) => {
    let table = "social";
    var itemId = 5;
    axios.post('get-item', { itemId, table })
    .then((res) => {
        if (res.data.message) {
        // Handle the case when the team data is not found
        } else {
        setWhatsapp(res.data);
        }
    })
    .catch((err) => console.log(err));
};

useEffect(() => {
    getProduct();
    getWhatsapp();
}, []);

useEffect(() => {
    getSupplier(product.supplier_id);

    if (product.specifications) {
        const specificationsarr = product.specifications.trim().split('\n');
        const result = [];
        specificationsarr.forEach(line => {
            const [key, value] = line.split(':').map(str => str.trim());
            result.push({ key, value });
        });

        console.log(result);

        setSpecifications(result);
    }
}, [product]);


// Function to handle the download action
const handleDownloadCatalog = () => {
    // Replace 'catalog.pdf' with the actual URL or path to your catalog file
    const catalogUrl =`uploads/${product.catalog}`;
    window.open(catalogUrl, '_blank');
};



    return(
        <>
            <section className='product-page'>
                <div className='container-fluid'>
                    <div className='row'>
                    {product ? ( // Check if product data is available
                        <>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='header'>
                            <h1> اسم المنتج : <span> {product.name} </span> </h1>
                            <h2> المورد : <span> {supplier.name} </span> </h2>
                            <h3> {product.country} </h3>
                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='product-img'>
                            <div className='catalog'>
                                {product.catalog ? (
                                <button className='download-catalog' onClick={handleDownloadCatalog}>
                                    تنزيل الكاتالوج <i className="las la-file-pdf"></i>
                                </button>
                                ) : null}
                            </div>

                            <div className='description'>
                                <p> {product.description} </p>
                            </div>

                            <div className='img'>
                                <div className='buttons'>
                                    <i class="las la-chevron-circle-left prev-btn"></i>
                                    <i class="las la-chevron-circle-right next-btn"></i>

                                </div>
                                <Swiper
                                grabCursor={true}
                                slidesPerView={1}
                                navigation={{
                                    nextEl: '.next-btn',
                                    prevEl: '.prev-btn',
                                }}
                                EffectCoverflow
                                EffectCube
                                pagination={true}
                                // autoplay={{
                                //     delay: 2000,
                                //     disableOnInteraction: false,
                                // }}
                                modules={[Autoplay, EffectCoverflow, EffectCube, Navigation, Pagination]}
                                >
                                {product.img.split(',').map((image, index) => (
                                    <SwiperSlide key={index}>
                                    <img src={`uploads/${image}`} alt={`product-img-${index}`} />
                                    </SwiperSlide>
                                ))}
                                </Swiper>
                                <a href={whatsapp.link}> تواصل معنا الان </a>
                            </div>
                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='specifications'>
                            <h1> مواصفات المنتج </h1>
                            {specifications.map((specification, index) => (
                                <div key={index}>
                                <span>{specification.key}:</span>
                                <span>{specification.value}</span>
                                </div>
                            ))}
                            </div>
                        </div>
                        </>
                    ) : (
                        // Render a loading indicator or nothing when product data is not available
                        <div>Loading...</div>
                    )}
                    </div>
                </div>
                </section>

        </>
    )
}


export default ProductPage;
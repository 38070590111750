import './css/categories.css';

import categoriesimg1 from './images/cat1.jpg';

function Categories() {
return (
    <>
    <section className='categories'>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
            <div className='cat-images'>
                <div className='color-back'></div>
                <div className='overlay'></div>
                <img src={categoriesimg1} />
            </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
            <div className='categories-text'>
                <h2>بذور ممتازة ومنتجات مختارة بعناية</h2>
                <h1>نحن نؤمن أن كل بذرة تحمل وعد رحلة جميلة</h1>
                <p>سواء كنت تزرع حديقة صغيرة على شرفتك، أو مزرعة واسعة، أو أي شيء آخر فيما بينهما، وادي الفلاح هنا لمرافقتك في رحلتك. ندعوك لاستكشاف مجموعتنا المتنوعة من البذور والمنتجات، والانطلاق في مغامرات زراعية جديدة، والتمتع بفرحة مشاهدة أحلامك تتفتح في الواقع. شكرًا لاختيار وادي الفلاح كشريك موثوق في جميع جهودك في الحدائق والزراعة.</p>

                <div className='category'>
                <div>
                    <i class="las la-seedling"></i>
                    <h1>بذور</h1>
                </div>

                <div>
                    <i class="las la-umbrella-beach"></i>
                    <h1>شبكات الظل</h1>
                </div>
                </div>
            </div>
            </div>  
        </div>
        </div>
    </section>
    </>
)
}

export default Categories;

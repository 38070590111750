import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import Landing from '../Landing';




function AllSuppliers(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllSuppliersContent, setAllSuppliersContent] = useState([]);

    axios.defaults.withCredentials = true;





    const getAllSuppliers = ()=>{
        axios.post('AllItems', {
            table: 'suppliers',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllSuppliersContent(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'suppliers',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                getAllSuppliers();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };




useEffect(() => {

    getAllSuppliers();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <Link className='new-item' to='/add-supplier'> +  مورد جديد</Link>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>الصورة</th>
                            <th> الاسم </th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllSuppliersContent.length ? (

                    AllSuppliersContent.map(supplier => {

                        return (
                            <tr key={supplier.id}>
                            <td><h6>{supplier.id}</h6></td>

                            <td><img src={`/uploads/${supplier.img}`} alt="Supplier Img"/></td>
                            <td><h6>{supplier.name}</h6></td>



                            <td>
                                <Link className='edit' to={`/edit-supplier?id=${supplier.id}`}> <i class="las la-edit"></i> </Link>
                            </td>

                            
                            <td>
                                <button className='delete' onClick={() => deleteItem(supplier.id)}> <i class="las la-trash-alt"></i> </button>
                            </td>

                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllSuppliers;